<template>
  <div class="account-navigation">
		<div class="account-navigation__header" v-if="user.name">
			<a class="account-navigation__avatar" href="#">
				<img :src="user.steam.photos[2].value" alt="">
				<span class="account-navigation__browse-icon">&nbsp;</span>
			</a>
			<div class="account-navigation__subtitle">Welcome back!</div>
			<div class="account-navigation__name h5">{{ user.name }}</div>
			<ul class="account-navigation__meta">
				<li>27<span>comments</span></li>
				<li>4<span>reviews</span></li>
				<li>{{ new Date(user.createdOn).toDateString() }}<span>joined on</span></li>
			</ul>
		</div>
		<ul class="account-navigation__menu">
			<router-link tag="li" to="/account/general" active-class="active" exact><a>Account Settings</a></router-link>
			<router-link tag="li" to="/account/wallet" active-class="active" exact><a>Gameplay wallet</a></router-link>
			<router-link tag="li" to="/account/gameplay" active-class="active" exact><a>Gameplay Settings</a></router-link>
		</ul>
	</div>
</template>

<script>
export default {
	computed: {
		user() {
			return this.$store.state.userAccount;
		},
	},
};
</script>

<style>

</style>
