<template>
  <main class="site-content account-page account-layout" id="wrapper">
    <account-sidebar />
    <router-view></router-view>
  </main>
</template>

<script>
import Sidebar from './Sidebar.vue';

export default {
	components: {
		'account-sidebar': Sidebar,
	},
};
</script>

<style scoped>
  .account-layout {
    align-items: unset !important;
    justify-content: unset !important;
  }
</style>
