var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "account-navigation" }, [
    _vm.user.name
      ? _c("div", { staticClass: "account-navigation__header" }, [
          _c(
            "a",
            { staticClass: "account-navigation__avatar", attrs: { href: "#" } },
            [
              _c("img", {
                attrs: { src: _vm.user.steam.photos[2].value, alt: "" }
              }),
              _c("span", { staticClass: "account-navigation__browse-icon" })
            ]
          ),
          _c("div", { staticClass: "account-navigation__subtitle" }, [
            _vm._v("Welcome back!")
          ]),
          _c("div", { staticClass: "account-navigation__name h5" }, [
            _vm._v(_vm._s(_vm.user.name))
          ]),
          _c("ul", { staticClass: "account-navigation__meta" }, [
            _vm._m(0),
            _vm._m(1),
            _c("li", [
              _vm._v(_vm._s(new Date(_vm.user.createdOn).toDateString())),
              _c("span", [_vm._v("joined on")])
            ])
          ])
        ])
      : _vm._e(),
    _c(
      "ul",
      { staticClass: "account-navigation__menu" },
      [
        _c(
          "router-link",
          {
            attrs: {
              tag: "li",
              to: "/account/general",
              "active-class": "active",
              exact: ""
            }
          },
          [_c("a", [_vm._v("Account Settings")])]
        ),
        _c(
          "router-link",
          {
            attrs: {
              tag: "li",
              to: "/account/wallet",
              "active-class": "active",
              exact: ""
            }
          },
          [_c("a", [_vm._v("Gameplay wallet")])]
        ),
        _c(
          "router-link",
          {
            attrs: {
              tag: "li",
              to: "/account/gameplay",
              "active-class": "active",
              exact: ""
            }
          },
          [_c("a", [_vm._v("Gameplay Settings")])]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_vm._v("27"), _c("span", [_vm._v("comments")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_vm._v("4"), _c("span", [_vm._v("reviews")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }